<template>
  <div class="container bgblue">
    <el-card class="form-card mt5">
      <div class="header">
        <el-row>
          <el-form :model="form" class="demo-form-inline" label-width="80px">
            <el-col :span="6">
              <el-form-item label="订单号:">
                <el-input
                  v-model="form.billCode"
                  placeholder="请输入订单号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
              <el-form-item label="入库单号:">
                <el-input
                  v-model="form.orderCode"
                  placeholder="请输入入库单号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col> -->

            <el-col :span="6">
              <el-form-item label="用户姓名:">
                <el-input
                  v-model="form.agentName"
                  placeholder="请输入用户姓名"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
              <el-form-item label="状态:">
                <el-select
                  v-model="form.status"
                  @change="changeStatus"
                  placeholder="请选择状态"
                  clearable
                >
                  <el-option label="已入库" value="0"></el-option>
                  <el-option label="未入库" value="1"></el-option>
                  <el-option label="已出库" value="2"></el-option>
                  <el-option label="未出库" value="3"></el-option>
                </el-select> </el-form-item
            ></el-col> -->
            <el-col :span="6">
              <el-form-item label="入库时间:">
                <el-date-picker
                  v-model="form.date"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="changeDate"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label-width="20px">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="onSubmit"
                  >查询</el-button
                >
                <el-button icon="el-icon-refresh" @click="onReset"
                  >重置</el-button
                >
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </el-card>
    <el-card class="form-card mt5">
      <div class="table">
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          height="calc(100vh - 250px)"
        >
          <el-table-column
            align="center"
            prop="date"
            label="操作"
            width="100"
            fixed
          >
            <template v-slot="{ row }">
              <el-button
                type="text"
                size="mini"
                class="btn-blue"
                @click="handelDetail(row)"
                >明细</el-button
              >
            </template>
          </el-table-column>
          <el-table-column align="center" prop="billCode" label="订单号">
          </el-table-column>
          <el-table-column
            align="center"
            prop="agentName"
            label="用户姓名"
            width="100"
          >
          </el-table-column>
          <!-- <el-table-column align="center" prop="bankingNumber" label="金融单号">
          </el-table-column> -->
          <el-table-column
            align="center"
            width="80"
            prop="orderType"
            label="订单类型"
          >
            <template v-slot="{ row }">
              <span v-if="row.billType == 1" style="color: #409eff"
                >入库单</span
              >
              <span v-if="row.billType == 2" style="color: #67c23a"
                >出库单</span
              >
              <span v-if="row.billType == 3" style="color: red">退货单</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="物料名称">
            <template v-slot="{ row }">
              {{ row.billItemList.map((item) => item.productName).join(",") }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="规格/型号">
            <template v-slot="{ row }">
              {{ row.billItemList.map((item) => item.specs).join(",") }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="品牌">
            <template v-slot="{ row }">
              {{ row.billItemList.map((item) => item.brand).join(",") }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="功率">
            <template v-slot="{ row }">
              {{ row.billItemList.map((item) => item.power).join(",") }}
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" label="单位">
            <template v-slot="{ row }">
              {{ row.billItemList.map((item) => item.unit).join(",") }}
            </template>
          </el-table-column> -->
          <el-table-column
            align="center"
            prop="combNum"
            label="数量"
            width="70"
          >
            <template v-slot="{ row }">
              {{
                row.billItemList
                  .map((item) => Number(item.num))
                  .reduce((a, b) => {
                    return a + b;
                  }, 0)
              }}
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            prop="sureTime"
            label="入库时间"
            width="140"
          >
          </el-table-column>
          <el-table-column align="center" label="状态" width="75">
            <template v-slot="{ row }">
              <el-button type="text" v-if="row.billType == 1" class="btn-orange"
                >入库</el-button
              >
              <el-button type="text" v-if="row.billType == 2" class="btn-green"
                >出库</el-button
              >
              <el-button type="text" v-if="row.billType == 3" class="btn-green"
                >退货</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="params.currPage"
            :page-size="params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totol"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 弹框 -->
    <div id="dialog">
      <el-dialog title="入库单" :visible.sync="showDialog" center>
        <div id="pdf">
          <el-row :gutter="20">
            <el-col :span="12"
              ><div>
                <span style="font-weight: 800">订单编号： </span>
                <span>{{ datailObj.billCode }}</span>
              </div></el-col
            >
            <el-col :span="12"
              ><div>
                <span style="font-weight: 800">入库时间:</span>
                <span>{{ datailObj.sureTime }}</span>
              </div></el-col
            >
          </el-row>
          <div style="margin-top: 10px">
            <el-table :data="detailList" border height="calc(100vh - 710px)">
              <el-table-column
                prop="productName"
                align="center"
                label="物料名称"
              ></el-table-column>
              <el-table-column
                prop="specs"
                align="center"
                label="物料分类"
              ></el-table-column>
              <el-table-column
                prop="power"
                align="center"
                label="功率"
              ></el-table-column>
              <el-table-column
                prop="num"
                align="center"
                label="数量"
              ></el-table-column>
            </el-table>
          </div>
          <div style="margin-top: 20px">
            <div style="font-weight: 800">签收图片：</div>
            <div v-for="(item, index) in houseWareBillImgList" :key="index">
              <el-image
                style="width: 200px; height: 200px"
                :src="item"
                :preview-src-list="houseWareBillImgList"
              >
              </el-image>
              <!-- <img class="img" :src="item.billImgUrl" alt="" /> -->
            </div>
          </div>
        </div>
        <div style="margin-top: 20px">
          <el-row type="flex" justify="center">
            <el-button type="primary" @click="importPdf"
              >下&nbsp;&nbsp;载</el-button
            >
            <el-button @click="showDialog = false">返&nbsp;&nbsp;回</el-button>
          </el-row>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      detailList: [], //详情表格
      datailObj: {}, //详情对象
      showDialog: false,
      form: {},
      params: {
        count: 0,
        currPage: 1,
        pageCount: 0,
        pageSize: 10,
      },
      totol: 0,
      houseWareBillImgList: [], //图片
    };
  },
  computed: {},
  methods: {
    onSubmit() {
      this.params.currPage = 1;
      this.getData();
    },
    handleRemove() {},
    importPdf() {
      let params = {
        title: "报告",
        name: "zhudying",
        code: "001",
      };
      let html = document.getElementById("pdf");
      console.log(html, 246);
      this.getPdf(html, params);
    },
    async getData() {
      let condition = {
        billType: "1", //入库标识
        loginName: localStorage.getItem("loginName"),
        ...this.form,
      };
      const res = await this.$http.post("/houseWareBill/listPlantform", {
        condition,
        ...this.params,
      });
      if (res.data.code === 200) {
        this.totol = res.data.data.count;
        this.tableData = res.data.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 查看明细
    async handelDetail(row) {
      this.datailObj = row;
      this.showDialog = true;
      const res = await this.$http.post("houseWareBill/detail", {
        billCode: row.billCode,
      });
      if (res.data.code === 200) {
        console.log(res.data.data);
        this.detailList = res.data.data.billItemList;
        this.houseWareBillImgList = res.data.data.houseWareBillImgList.map(
          (item) => item.billImgUrl
        );
      } else {
        this.$message.error(res.data.message);
      }
    },
    // //状态
    // changeStatus(val) {
    //   this.form.status = val;
    // },
    //日期
    changeDate(val) {
      console.log(val);
      if (val) {
        this.form.beginTime = val[0];
        this.form.endTime = val[1];
      } else {
        this.form.beginTime = "";
        this.form.endTime = "";
      }
    },
    onReset() {
      // this.form.bankingNumber = "";
      // this.form.date = "";
      // this.form.orderCode = "";
      // this.form.status = "";
      // this.form.userName = "";
      this.form = {};
      this.getData();
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.params.currPage = val;
      this.getData();
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 5px;
  box-sizing: border-box;
  ::v-deep .el-input__inner {
    width: 100% !important;
  }
  ::v-deep .el-dialog__header {
    background-color: #f6f8fb;
  }
  ::v-deep .el-select {
    width: 100% !important;
  }
  ::v-deep .el-form-item__label {
    font-size: 13px;
  }
  ::v-deep .el-table {
    margin-top: 0px !important;
  }
  .header {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    padding-bottom: 0px !important;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px #ccc;
  }
  .table {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;

    box-sizing: border-box;

    height: calc(100vh - 190px);

    ::v-deep .el-button--primary.is-plain {
      height: 20px !important;
      line-height: 6px !important;
    }

    ::v-deep .el-table td {
      padding: 0.5px 0;
      box-sizing: border-box;
    }
  }
  .page {
    width: 100%;
    text-align: end;
    ::v-deep .el-input__inner {
      height: 25px !important;
    }
  }
  #dialog {
    // ::v-deep .el-button {
    //   height: 35px !important;
    // }

    .img {
      width: 150px;
      height: 150px;
      margin: 10px;
    }
  }
}

#pdf {
  background-color: #fcfdfe !important;
  padding: 10px;
  box-sizing: border-box;
}

::v-deep .el-dialog__body {
  padding: 0 0 30px 0 !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}
::v-deep .el-card__body {
  padding: 0px !important;
}
::v-deep .el-card {
  border-radius: 10px;
}
</style>
